export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,2,5,8,10];

export const dictionary = {
		"/": [14],
		"/(app)/account": [~15,[2],[3]],
		"/(app)/account/payment-methods": [~16,[2],[3]],
		"/(app)/account/payment-methods/add": [17,[2],[3]],
		"/(app)/account/payment-methods/update": [~18,[2],[3]],
		"/(app)/account/payouts/update": [~19,[2],[3]],
		"/(app)/account/tag": [~20,[2],[3]],
		"/(app)/account/tag/download": [21,[2],[3]],
		"/(app)/admin": [~22,[2],[3]],
		"/api/waitlist": [51],
		"/auth/auth-code-error": [52,[],[12]],
		"/blog": [~53,[13]],
		"/blog/page/[blogPage]": [~55,[13]],
		"/blog/[slug]": [~54,[13]],
		"/(app)/calls": [23,[2],[3]],
		"/(app)/calls/[callId]": [~24,[4]],
		"/(app)/chats": [25,[2,5],[3]],
		"/(app)/chats/[chatId]": [~26,[2,5],[3]],
		"/(app)/checkout": [~27,[2],[3]],
		"/(app)/dashboard": [~28,[2],[3]],
		"/(app)/delete-account": [29,[2],[3]],
		"/faq": [56],
		"/help": [57],
		"/(app)/home": [30,[2],[3]],
		"/join": [~58],
		"/(app)/library": [~31,[2],[3]],
		"/(app)/listings": [~32,[2,6],[3]],
		"/(app)/listings/create": [33,[2,6],[3]],
		"/(app)/listings/edit/[listingId]": [~34,[2,6],[3]],
		"/(app)/orders": [~35,[2],[3]],
		"/(app)/orders/[orderId]/receipt": [36,[7]],
		"/(app)/posts": [~37,[2],[3]],
		"/(app)/posts/create": [~39,[2],[3]],
		"/(app)/posts/[slug]": [~38,[2],[3]],
		"/privacy": [~59],
		"/reset-password": [60],
		"/(app)/sales": [~40,[2],[3]],
		"/(app)/settings": [41,[2],[3]],
		"/sexyjobs": [~61],
		"/signin": [62],
		"/terms": [63],
		"/(app)/tips": [~42,[2],[3]],
		"/(app)/update-password": [43,[8]],
		"/(app)/upload": [44,[2],[3]],
		"/(app)/verified": [45,[9]],
		"/(app)/verified/browse": [46,[9]],
		"/(app)/verified/channels": [47,[9,10]],
		"/(app)/verified/channels/[channelId]": [~48,[9,10]],
		"/(app)/verified/unauthorized": [49,[9]],
		"/[providerName]": [~50,[],[11]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';